import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContentBlocks from "../components/content-blocks"
import GridItems from "../components/grid-items"
import NewsLetterSignup from "../components/newsLetterSignup"

export default ({ data }) => {
  const { title, contentBlocks, bodyBgColor, bodyTextColor, bodyTitleColor, seo } = data.contentfulHomePage
  const postsList = data.contentfulHomePage.postsList
  const newsletter = data.contentfulHomePage.newsletter
  const collection = postsList.map( (post) => { return {node: post} })

  return (
    <Layout
      bodyBgColor={bodyBgColor}
      bodyTextColor={bodyTextColor}
      bodyTitleColor={bodyTitleColor}
      pageTitle={seo && seo.seoTitle ? seo.seoTitle : title}
      pageDescription={seo && seo.description ? seo.description : null}
      pageImage={seo && seo.image ? seo.image.file.url : null}
      pageArticle={seo && seo.article ? seo.article : false}
    >
      {
        contentBlocks.length > 0 &&
        <div className="mt-20 md:mt-0">
          <ContentBlocks contentBlocks={contentBlocks} />
        </div>
      }
      {
        collection &&
        <div className="mt-0">
          <GridItems collection={collection} is_index={true} />
        </div>
      }
      { newsletter &&
        <NewsLetterSignup newsletter={newsletter} />
      }
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contentfulHomePage(slug: {eq: "home"}) {
      seo {
        ... on Node {
            ... on ContentfulSeo {
            article
            seoTitle
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
      title
      bodyBgColor
      bodyTextColor
      bodyTitleColor
      postsList {
        __typename
        ... on Node {
          ... on ContentfulProject {
            createdAt(formatString: "MMMM D, Y")
            featuredImage {
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            intro {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 250, truncate: true)
              }
            }
            slug
            title
            client
            tags {
              title
            }
          }
          ... on ContentfulPost {
            createdAt(formatString: "MMMM D, Y")
            datePublished(formatString: "MMMM D, Y")
            featuredImage {
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            intro {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 250, truncate: true)
              }
            }
            slug
            title
          }
        }
      }
      contentBlocks {
        __typename
        ... on Node {
          ... on ContentfulTwoColumnMedia {
            backgroundColor
            id
            bottomMedia {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            marginBottom
            marginTop
            reverseOrderDesktop
            reverseOrderMobile
            standAloneMedia {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            topMedia {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulHeroImage {
            container
            marginTop
            marginBottom
            backgroundColor
            image {
              svg {
                  content
                  dataURI
                  absolutePath
                  relativePath
                }
                file {
                  contentType
                  url
                  fileName
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
                fluid(maxWidth: 2560, quality: 70) {
                  ...GatsbyContentfulFluid_withWebp
                }
            }
          }
          ... on ContentfulExternalVideo {
            backgroundColor
            marginTop
            marginBottom
            videoProvider
            videoId
            useContainer
          }
          ... on ContentfulHeroVideo {
            backgroundColor
            marginTop
            marginBottom
            container
            showPlayButton
            graphicOverlay {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            video {
              file {
                url
              }
            }
            poster {
              resize(width: 1920, height: 1080) {
                src
              }
            }
          }
          ... on ContentfulTwoColumn {
            marginTop
            marginBottom
            backgroundColor
            textColor
            media {
              file {
                contentType
                url
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            title
            reverseDesktop
            reverseMobile
          }
          ... on ContentfulPromotion {
            negativeMarginTop
            marginTop
            marginBottom
            title
            textCenter
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulPromotionSecondary {
            marginTop
            marginBottom
            title
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulOneColumn {
            marginTop
            marginBottom
            title
            largeText
            textCentered
            backgroundColor
            textColor
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulRelated {
            marginTop
            marginBottom
            viewTitle
            backgroundColor
            textColor
            relatedItems {
              __typename
              ... on Node {
                ... on ContentfulProject {
                  slug
                  title
                  featuredImage {
                    fluid(maxWidth: 1020, quality: 70) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  intro {
                    childMarkdownRemark {
                      excerpt(pruneLength: 200, truncate: true, format: PLAIN)
                    }
                  }
                }
                ... on ContentfulPost {
                  slug
                  title
                  featuredImage {
                    fluid(maxWidth: 1020, quality: 70) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  intro {
                    childMarkdownRemark {
                      excerpt(pruneLength: 200, truncate: true, format: PLAIN)
                    }
                  }
                }
              }
            }
          }
        }
      }
      newsletter {
        ... on Node {
          ... on ContentfulNewsletter {
            id
            description {
              childMarkdownRemark {
                html
              }
            }
            disclaimer {
              childMarkdownRemark {
                html
              }
            }
            title
            darkMode
            successMessage
          }
        }
      }
    }
  }
`
