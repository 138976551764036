import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default (props) => {

  const renderTitle = (itemNode) => {

    return <h3 className="underline my-2">{itemNode.title}</h3>
  }

  const renderTagsOrDate = (itemNode) => {
    if (itemNode.__typename === 'ContentfulPost') {
      return (
        <span>{itemNode.datePublished ? itemNode.datePublished : itemNode.createdAt}</span>
      )
    }
    let tags = itemNode?.tags ?? []
    tags = tags.map( tag => tag.title)

    return (
      <span>
        {itemNode.client}
        { tags.length > 0 &&
          <> - {tags.join(', ')}</>
        }
      </span>
    )
  }

  const generateUrl = (typename, slug) => {
    if (typename === 'ContentfulPost') {
      return `/news/${slug}/`
    }

    return `/work/${slug}/`
  }

  return (
    <div className="container">
      <div className={`flex flex-wrap -mx-12 ${props.is_index ? ' index' : ''}`}>

        {props.collection.map((item, index) => (
          <div key={index} className="grid-item w-full px-12 mb-20 md:mb-26 md:w-1/2 lg:w-1/2">
            <Link to={generateUrl(item.node.__typename, item.node.slug)} className="group-hover">
              {item.node.featuredImage && <Img fluid={item.node.featuredImage.fluid} className="mb-6" />}
              { renderTagsOrDate(item.node) }
              {item.node.title && renderTitle(item.node)}
              {item.node.intro && <div className="mt-2">{item.node.intro.childMarkdownRemark.excerpt}</div>}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
