import React, { useCallback, useRef, useState } from "react"
import jsonp from 'jsonp';

const NewsLetterSignup = ({newsletter})=>{

    const mailForm = useRef()
    const [submitted, setSubitted] = useState(false)
    const [emailValid, setEmailValid] = useState(true)
    const [successful, setSuccessful] = useState(true)

    const darkmode = newsletter.darkMode !== false

    const description = newsletter.description.childMarkdownRemark.html
    const disclaimer = newsletter.disclaimer.childMarkdownRemark.html
    const successMessage = newsletter.successMessage
    const title = newsletter.title
    const endpoint = "https://ehrenstrahle.us1.list-manage.com/subscribe/post-json?u=050cf885b5528844be8f4d4a2&id=cb79486c4c" 

    const emailIsValid = (email)=>{
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        const email = mailForm.current.value

        if( emailIsValid(email) && !submitted ) {

          const params = new URLSearchParams();
          params.set('EMAIL', email)
          params.set('b_050cf885b5528844be8f4d4a2_cb79486c4c', ''); // hidden input name
          params.set('gdpr[42678]', 'Y'); // hidden input name

          const url = endpoint + '&' + params.toString()

          new Promise((resolve, reject) =>
            jsonp(url, { param: 'c', timeout: 3500 }, (err, data) => {
                if (err) reject(err);
                if (data) resolve(data);
            })
          ).then((response)=>{
            console.log(response)
            setSuccessful(true)
            setSubitted(true)
          }).catch((error)=>{
            console.warn(error)
            setSuccessful(false)
          })

        } else {
          setEmailValid(false)
        }

    },[mailForm, submitted])

    return (
        <section className='relative py-20 md:py-25 xl:py-26'>
          <div className="container">
            <div className={`flex flex-wrap -mx-12 py-20 ${darkmode ? 'text-white bg-black' : 'text-black bg-white'}`}>
              <div className='grid-item w-full px-12 mb-8 md:w-1/2 lg:w-1/2'>
                {
                  title &&
                  <h2 className={darkmode ? 'text-white':'text-black'}>{title}</h2>
                }
              </div>
              <div className='grid-item w-full px-12 mb-8 md:w-1/2 lg:w-1/2'>
                { description && <div className="mb-8" dangerouslySetInnerHTML={{ __html: description }}></div>}
                { !submitted && 
                  <form onSubmit={handleSubmit} method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
                      <input ref={mailForm} type="email" placeholder="E-mail address" name="EMAIL" className={`w-full ${darkmode ? 'bg-black text-white border-white' :'bg-white text-black border-black' } py-2 px-4 border`} id="EMAIL" required />
                      <button type="submit" className={`${darkmode ? 'bg-white text-black border-white' : 'bg-black text-white border-black'} py-2 px-4 mt-4 border`}>Sign up</button>
                      { !emailValid && 
                        <div className="text-red mt-4 text-sm">E-mail is not valid</div>
                      }
                      { !successful && 
                        <div className="text-red mt-4 text-sm">Something went wrong, please try again later...</div>
                      }
                  </form>
                }
                { submitted && successful &&
                  <p className="font-medium">{successMessage}</p>
                }
                <div className={`disclaimer text-xs mt-4 ${darkmode ? 'text-white':'text-black'}`} dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
              </div>            
            </div>
          </div>
        </section>
    )
}

export default NewsLetterSignup;